import React from "react";

interface Props {
    fill: string;
}

export const QualityAssuranceIcon: React.FC<Props> = ({ fill }) => {
    return (
        <svg width="29" height="29" viewBox="0 0 29 29" fill={fill}>
            <path d="M22.8,7.7l-0.4-0.6c-0.3-0.4-0.5-0.9-0.6-1.4L21.7,5c-0.2-1.4-1.3-2.5-2.7-2.7l-0.7-0.1c-0.5-0.1-1-0.3-1.4-0.6l-0.6-0.4
            c-1.1-0.8-2.7-0.8-3.8,0L12,1.6c-0.4,0.3-0.9,0.5-1.4,0.6L9.9,2.3C8.6,2.5,7.5,3.6,7.3,5L7.2,5.7c-0.1,0.5-0.3,1-0.6,1.4L6.2,7.7
            c-0.8,1.1-0.8,2.7,0,3.8l0.4,0.6c0.3,0.4,0.5,0.9,0.6,1.4l0.1,0.7c0.2,1.4,1.3,2.5,2.7,2.7l0.7,0.1c0.5,0.1,1,0.3,1.4,0.6l0.6,0.4
            c1.1,0.8,2.7,0.8,3.8,0l0.6-0.4c0.4-0.3,0.9-0.5,1.4-0.6l0.7-0.1c1.4-0.2,2.5-1.3,2.7-2.7l0.1-0.7c0.1-0.5,0.3-1,0.6-1.4l0.4-0.6
            C23.6,10.4,23.6,8.8,22.8,7.7z M19.1,11.2c-0.1,0.2-0.2,0.4-0.3,0.7l-0.1,0.7c-0.1,0.7-0.6,1.2-1.3,1.4L16.7,14
            c-0.2,0-0.5,0.1-0.7,0.3l-0.6,0.4c-0.6,0.4-1.3,0.4-1.9,0L13,14.3c-0.2-0.1-0.4-0.2-0.7-0.3l-0.7-0.1c-0.7-0.1-1.2-0.7-1.3-1.4
            l-0.1-0.7c0-0.2-0.1-0.5-0.3-0.7l-0.4-0.6c-0.4-0.6-0.4-1.3,0-1.9l0.4-0.6c0.1-0.2,0.2-0.4,0.3-0.7l0.1-0.7
            c0.1-0.7,0.6-1.2,1.3-1.4l0.7-0.1c0.2,0,0.5-0.1,0.7-0.3l0.6-0.4c0.6-0.4,1.3-0.4,1.9,0L16,4.9c0.2,0.1,0.4,0.2,0.7,0.3l0.7,0.1
            c0.7,0.1,1.2,0.7,1.3,1.4l0.1,0.7c0,0.3,0.1,0.5,0.3,0.7l0.4,0.6c0.4,0.6,0.4,1.3,0,1.9L19.1,11.2z"/>
            <path d="M16.2,21.7l5.1,1.9L20,18.4c-0.2,0.1-0.5,0.1-0.7,0.2l-0.7,0.1c-0.2,0-0.5,0.1-0.7,0.3l-0.6,0.4c-0.2,0.2-0.5,0.3-0.7,0.4
            L16.2,21.7z"/>
            <path d="M14.5,20.4c-1,0-2-0.3-2.8-0.9L11.1,19c-0.2-0.1-0.4-0.2-0.7-0.3l-0.7-0.1c-0.3,0-0.6-0.1-0.8-0.2L6.4,28.5l3.9-2.7
            l2.5,2.7l2-8.1C14.7,20.3,14.6,20.4,14.5,20.4z"/>
        </svg>
    )
}