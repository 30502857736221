import React from "react";
import { GameProgrammingIcon } from "../../components/layout/icons/GameProgrammingIcon";
import { PrototypingIcon } from "../../components/layout/icons/PrototypingIcon";
import { ToolDevelopmentIcon } from "../../components/layout/icons/ToolDevelopmentIcon";
import { PortingIcon } from "../../components/layout/icons/PortingIcon";
import { QualityAssuranceIcon } from "../../components/layout/icons/QualityAssuranceIcon";
import { CoDevelopmentIcon } from "../../components/layout/icons/CoDevelopmentIcon";
import { GameDevelopmentServiceData } from "./GameDesignData";

export const gameProgrammingPageData: GameDevelopmentServiceData = {
    metaPageTitle: "Game Programming - iDream Interactive",
    metaKeywords: "video game programming, game programming, porting game, video game qa, prototype game",
    metaDescription: "Need someone to create your dream game? We can help with initial prototypes, creating custom tooling and even porting your game from another technology.",
    title: "Game Programming",
    subtitle: "Need someone to create your dream game?  We can help with initial prototypes, creating custom tooling and even porting your game from another technology.",
    icon: <GameProgrammingIcon fill="#ffffff" />,
    services: [
        {
            title: "Prototyping",
            description: (<>
                <p>A prototype is a small simplified version of your game. Making a prototype helps you determine if a game idea will be worth pursuing further. We believe in the “fail fast” approach. The quicker we can prototype an idea and test it, the quicker we can choose a winning idea or pivot an idea.</p>
            </>),
            icon: <PrototypingIcon fill="#080a2e" />,
            image: "",
            imageAlt: "Nina Nutmeg playing with building blocks",
        },
        {
            title: "Tool Development",
            description: (<>
                <p>When we create tools, we think about how to simplify a process to save you time and money. We have created tools for various processes such as automated encomy balancing, analytics data exporting & executive dashboards, capacity testing, A/B testing, AI driven trend analysis and more. If you can think of it, we can build it.</p>
            </>),
            icon: <ToolDevelopmentIcon fill="#03041d" />,
            image: "",
            imageAlt: "Bill Durr fixing parts with a wrench",
        },
        {
            title: "Porting",
            description: (<>
                <p>We approach porting games by first learning about your current games technology and needs. Through this we can better understand why your current technology stack fails to meet your requirements.</p>
                <p>Once we have a good idea of what you would like to accomplish, we can begin rebuilding your project. In most cases we are able to reuse many of the original games assets and logic to rebuild a better version of your game to abide by your new requirements.</p>
            </>),
            icon: <PortingIcon fill="#080a2e" />,
            image: "",
            imageAlt: "Nina Nutmeg coding",
        },
        {
            title: "Quality Assurance",
            description: (<>
                <p>Quality Assurance Testing is vital when developing any game. By taking the time to test thoroughly throughout development, we can minimize the amount of issues a user will encounter when playing a game.</p>
                <p>We do this in various ways which include designing test cases, regression testing, load testing, and more.</p></>),
            icon: <QualityAssuranceIcon fill="#ffffff" />,
            image: "",
            imageAlt: "Person soldering a circuit board",
        },
        {
            title: "Co-Development",
            description: (<>
                <p>If you lack the technical no-how or just need an extra pair of hands, we are here to help. If you would like to work hand in hand with us through the development process of your game, we can accomodate you.</p>
            </>),
            icon: <CoDevelopmentIcon fill="#03041d" />,
            image: "",
            imageAlt: "Two men reviewing a document",
        },
    ]
}