

import React from "react";

interface Props {
    fill: string;
}

export const ToolDevelopmentIcon: React.FC<Props> = ({ fill }) => {
    return (
        <svg width="29" height="29" viewBox="0 0 29 29" fill={fill}>
            <path d="M19.1,15.4l-3.5,3.9l8.6,8.6c0.7,0.7,1.8,0.7,2.5,0l1.2-1.2c0.7-0.7,0.7-1.8,0-2.4L19.1,15.4z" />
            <path d="M4,5.9l6.7,6.7l1.9-1.7L5.8,4V2.4L2.4,0.7L0.6,2.4l1.7,3.5H4z" />
            <path d="M28,4.7L24.7,8L21,4.3L24.3,1c-2.1-0.7-4.6-0.3-6.3,1.4l-1.8,1.8c-1.7,1.7,1.8,4,0.4,5.3l0,0L1.1,23.4
		    c-0.7,0.7-0.7,1.8,0,2.4l1.9,1.9c0.7,0.7,1.8,0.7,2.5,0l13.9-15.4l0,0c1.4-1.4,3.7,2.1,5.3,0.4l1.8-1.8C28.3,9.3,28.7,6.8,28,4.7z"
            />
        </svg>
    )
}