import React from "react";

interface Props {
    fill: string;
}

export const CoDevelopmentIcon: React.FC<Props> = ({ fill }) => {
    return (
        <svg width="29" height="29" viewBox="0 0 29 29" fill={fill}>
            <path d="M18,10.2c0,1.9-1.6,4.4-3.5,4.4c-1.9,0-3.5-2.4-3.5-4.4c0-1.9,1.6-3.5,3.5-3.5C16.4,6.7,18,8.2,18,10.2z" />
            <path d="M20,22.3H9c-0.9,0-1.6-0.7-1.6-1.6v0c0-3.4,2.8-4.7,6.2-4.7h1.6c3.4,0,6.2,1.2,6.2,4.7v0C21.5,21.6,20.8,22.3,20,22.3z" />
            <path d="M25.9,11.7c0,1.4-1.2,3.3-2.6,3.3c-1.5,0-2.6-1.8-2.6-3.3c0-1.4,1.2-2.6,2.6-2.6C24.7,9,25.9,10.2,25.9,11.7z" />
            <path d="M23.9,16.1h-1.2c-0.6,0-1.1,0.1-1.6,0.2c1.3,1.1,2,2.6,2,4.5h4.3c0.6,0,1.2-0.5,1.2-1.2C28.6,17,26.5,16.1,23.9,16.1z" />
            <path d="M3.1,11.7c0,1.4,1.2,3.3,2.6,3.3c1.5,0,2.6-1.8,2.6-3.3C8.4,10.2,7.2,9,5.7,9C4.3,9,3.1,10.2,3.1,11.7z" />
            <path d="M5.1,16.1h1.2c0.6,0,1.1,0.1,1.6,0.2c-1.3,1.1-2,2.6-2,4.5l-4.3,0c-0.6,0-1.2-0.5-1.2-1.2C0.4,17,2.5,16.1,5.1,16.1z" />
        </svg>
    )
}