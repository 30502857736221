

import React from "react";

interface Props {
    fill: string;
}

export const PrototypingIcon: React.FC<Props> = ({ fill }) => {
    return (
        <svg width="29" height="29" viewBox="0 0 29 29" fill={fill}>
            <path d="M28.2,19.1L27.1,12c-0.6-4.4-4.4-7.7-8.8-7.7h-3.8h-3.8c-4.4,0-8.2,3.3-8.8,7.7l-1.1,7.1c-0.5,3.4,2.4,6.5,6.2,5.3
            c1.6-0.5,2.8-1.7,3.4-3.3l0.7-2.2c0.2-0.7,0.9-1.2,1.7-1.2l1.8,0l1.8,0c0.8,0,1.5,0.5,1.7,1.2l0.7,2.2c0.6,1.5,1.9,2.8,3.4,3.3
            C25.8,25.6,28.7,22.5,28.2,19.1z M24.4,20.8c-0.2,0.2-0.4,0.3-0.7,0.3c-0.2,0-0.3,0-0.5-0.1c-0.5-0.2-0.9-0.6-1.2-1.1l-0.6-2
            c-0.7-2.2-2.7-3.7-5.1-3.7h-3.5c-2.3,0-4.4,1.5-5.1,3.7l-0.6,2c-0.3,0.6-0.7,1-1.2,1.1c-0.2,0.1-0.4,0.1-0.5,0.1
            c-0.3,0-0.5-0.1-0.7-0.3c-0.3-0.3-0.4-0.7-0.3-1.1l1.1-7.1C5.8,9.9,8,7.9,10.7,7.9h7.7c2.6,0,4.9,2,5.3,4.6l1.1,7.1
            C24.8,20,24.7,20.5,24.4,20.8z"/>
            <path d="M16.3,9.7c0,1-0.8,1.8-1.8,1.8c-1,0-1.8-0.8-1.8-1.8c0-1,0.8-1.8,1.8-1.8C15.5,7.9,16.3,8.7,16.3,9.7z" />
        </svg>
    )
}