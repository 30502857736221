import React from "react"
import Img from "gatsby-image";
import Layout from "../../components/layout/Layout";
import { graphql } from "gatsby"
import { Navigation } from "../../components/navigation/Navigation";
import { HeaderSize, ParallaxHeader } from "../../components/layout/ParallaxHeader";
import { HeaderInfo } from "../../components/layout/HeaderInfo";
import { HeaderImageContainer } from "../../components/layout/HeaderImageContainer";
import { Container } from "../../components/layout/Container";
import { ContainerColour, DiagonalContainer, ContainerSize, Direction } from "../../components/layout/DiagonalContainer";
import { LeftContainer } from "../../components/layout/LeftContainer";
import { RightContainer } from "../../components/layout/RightContainer";
import { gameProgrammingPageData } from "../../content/pageData/GameProgrammingData";
import { ButtonColour, ButtonSize, Button } from "../../components/layout/Button";

type PageData = {
    data: {
        site: {
            siteMetadata: {
                title: string;
                description: string;
            }
        }
        octopusMobile: {
            childImageSharp: any;
        }
        octopus: {
            childImageSharp: any;
        }
        prototyping: {
            childImageSharp: any;
        }
        toolDevelopment: {
            childImageSharp: any;
        }
        porting: {
            childImageSharp: any;
        }
        qualityAssurance: {
            childImageSharp: any;
        }
        coDevelopment: {
            childImageSharp: any;
        }
        allMarkdownRemark: {
            edges: [{
                node: {
                    fields: {
                        slug: string
                    }
                    frontmatter: {
                        title: string
                    }
                }
            }]
        }
    }
}

export default ({ data }: PageData) => {
    const title = gameProgrammingPageData.title;
    const octopusMobile = data.octopusMobile.childImageSharp.fluid;
    const octopus = data.octopus.childImageSharp.fluid;
    const octopusSources = [
        octopusMobile,
        {
            ...octopus,
            media: `(min-width: 768px)`,
        }
    ]

    return (
        <Layout
            pageTitle={gameProgrammingPageData.metaPageTitle}
            keywords={gameProgrammingPageData.metaKeywords}
            description={gameProgrammingPageData.metaDescription}
            url={"game-development/game-programming"}
        >
            <Navigation />
            <ParallaxHeader size={HeaderSize.MEDIUM}>
                <HeaderInfo
                    title={title}
                    icon={gameProgrammingPageData.icon}
                    subtitle={gameProgrammingPageData.subtitle}
                    className="game-development-service-header game-programming"
                />
            </ParallaxHeader>
            <HeaderImageContainer>
                <Img className="octopus" fluid={octopusSources} alt={`Pink Octopus`} />
            </HeaderImageContainer>
            <Container className="non-diagonal-container first-container">
                <LeftContainer
                    icon={gameProgrammingPageData.services[0].icon}
                    title={gameProgrammingPageData.services[0].title}
                    description={gameProgrammingPageData.services[0].description}
                    image={data.prototyping.childImageSharp.fluid}
                    imageAlt={gameProgrammingPageData.services[0].imageAlt}
                />
            </Container>
            <DiagonalContainer size={ContainerSize.MEDIUM} colour={ContainerColour.ORANGE} hasRips={false} withPad={true}>
                <RightContainer
                    icon={gameProgrammingPageData.services[1].icon}
                    title={gameProgrammingPageData.services[1].title}
                    description={gameProgrammingPageData.services[1].description}
                    image={data.toolDevelopment.childImageSharp.fluid}
                    imageAlt={gameProgrammingPageData.services[1].imageAlt}
                />
            </DiagonalContainer>
            <Container className="non-diagonal-container">
                <LeftContainer
                    icon={gameProgrammingPageData.services[2].icon}
                    title={gameProgrammingPageData.services[2].title}
                    description={gameProgrammingPageData.services[2].description}
                    image={data.porting.childImageSharp.fluid}
                    imageAlt={gameProgrammingPageData.services[2].imageAlt}
                />
            </Container>
            <DiagonalContainer size={ContainerSize.MEDIUM} colour={ContainerColour.BLUE} direction={Direction.LEFT} hasRips={false} withPad={true}>
                <RightContainer
                    icon={gameProgrammingPageData.services[3].icon}
                    title={gameProgrammingPageData.services[3].title}
                    description={gameProgrammingPageData.services[3].description}
                    image={data.qualityAssurance.childImageSharp.fluid}
                    imageAlt={gameProgrammingPageData.services[3].imageAlt}
                />
            </DiagonalContainer>
            <Container className="non-diagonal-container">
                <LeftContainer
                    icon={gameProgrammingPageData.services[4].icon}
                    title={gameProgrammingPageData.services[4].title}
                    description={gameProgrammingPageData.services[4].description}
                    image={data.coDevelopment.childImageSharp.fluid}
                    imageAlt={gameProgrammingPageData.services[4].imageAlt}
                />
            </Container>
            <Container className="game-development-container centered-button">
                <Button
                    buttonConfig={{
                        colour: ButtonColour.BLUE,
                        size: ButtonSize.LARGE,
                        ctaLabel: "Back",
                        ctaLink: "/game-development",
                        id: "blueGradient",
                        ariaLabel: "Return to Game Development"
                    }}
                />
            </Container>
        </Layout>
    );
};



export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
        octopusMobile: file(relativePath: { eq: "assets/images/game-programming/octopus_small.png" }) {
            childImageSharp {
                fluid(maxWidth: 768, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        octopus: file(relativePath: { eq: "assets/images/game-programming/octopus.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        prototyping: file(relativePath: { eq: "assets/images/game-programming/prototyping.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        toolDevelopment: file(relativePath: { eq: "assets/images/game-programming/toolDevelopment.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        porting: file(relativePath: { eq: "assets/images/game-programming/porting.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        qualityAssurance: file(relativePath: { eq: "assets/images/game-programming/qualityAssurance.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        coDevelopment: file(relativePath: { eq: "assets/images/game-programming/coDevelopment.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`