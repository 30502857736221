

import React from "react";

interface Props {
    fill: string;
}

export const PortingIcon: React.FC<Props> = ({ fill }) => {
    return (
        <svg width="29" height="29" viewBox="0 0 29 29" fill={fill}>
            <path d="M24.9,2.4H4.1C2.2,2.4,0.7,4,0.7,5.9V18c0,1.9,1.6,3.5,3.5,3.5h7.8v3.5H11c-1,0-1.7,0.8-1.7,1.7h4.3h1.7h4.3
            c0-1-0.8-1.7-1.7-1.7h-0.9v-3.5h7.8c1.9,0,3.5-1.6,3.5-3.5V5.9C28.3,4,26.8,2.4,24.9,2.4z M24.9,16.2c0,1-0.8,1.7-1.7,1.7h-7.8
            h-1.7H5.8c-1,0-1.7-0.8-1.7-1.7V7.6c0-1,0.8-1.7,1.7-1.7h17.3c1,0,1.7,0.8,1.7,1.7V16.2z"/>
        </svg>
    )
}